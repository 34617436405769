import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import { SectionTitle, ColorPrimary } from 'components/Common';
import {
  Wrapper,
  Content,
  Column,
  Subtitle,
  Text,
  List,
  Item,
  CoverWrapper,
  Cover,
  ListImg,
} from './styles';

const data = graphql`
  {
    file(relativePath: { eq: "images/hero.png" }) {
      childImageSharp {
        fluid(quality: 60, maxWidth: 490, maxHeight: 384) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

type Props = {
  id: string;
  title: string;
};

const CreditsHeaderView: FC<Props> = ({ id, title }) => {
  const { file } = useStaticQuery(data);

  return (
    <Wrapper id={id}>
      <Content>
        <Column>
          <SectionTitle>
            {title} z <ColorPrimary>Simple Finance</ColorPrimary>
          </SectionTitle>
          <Text as="p">
            Masz skomplikowaną sytuację w firmie?
            <span>Wiemy co zrobić abyś uzyskał potrzebne finansowanie.</span>
          </Text>
          <Subtitle>Nasz zespół specjalistów od trudnych zadań:</Subtitle>
          <List>
            <Item>
              <ListImg src="/icons/list/chat.svg" />
              Odpowie na Twoje pytania dotyczące kredytu
            </Item>
            <Item>
              <ListImg src="/icons/list/magnifier.svg" />
              Wyszuka dla Ciebie najlepszy kredyt
            </Item>
            <Item>
              <ListImg src="/icons/list/book.svg" />
              Pomoże w wypełnieniu wniosku kredytowego
            </Item>
          </List>
        </Column>
        <CoverWrapper>
          <Cover as={Img} fluid={file.childImageSharp.fluid} />
        </CoverWrapper>
      </Content>
    </Wrapper>
  );
};

export default CreditsHeaderView;
