import React, { FC } from 'react';
import { PageProps } from 'gatsby';

import Head from 'views/Head';
import CreditsHeader from 'views/CreditsHeader';
import CreditsOffer from 'views/Offer/Credits';

const CreditPage: FC<PageProps> = () => (
  <>
    <Head title="Kredyty" />
    <CreditsHeader id="credits_hero" title="Kredyty" />
    <CreditsOffer id="kredyty" />
  </>
);

export default CreditPage;
