import styled from 'styled-components';
import { FluidObject } from 'gatsby-image';
import {
  SectionWrapper,
  ContentWrapper,
  SectionSubtitle,
} from 'components/Common';

export const Wrapper = styled(SectionWrapper)`
  margin: 13.5rem auto;
`;

export const Content = styled(ContentWrapper)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Column = styled.div`
  max-width: 480px;
`;

export const Subtitle = styled(SectionSubtitle)`
  margin-bottom: 1.8rem;
`;

export const Text = styled(SectionSubtitle)`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.9rem;
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  list-style: none;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  margin: 0 0 1rem;
`;

export const ListImg = styled.img`
  display: block;
  width: 60px;
  height: 45px;
  margin-right: 1rem;
`;

export const CoverWrapper = styled.div`
  flex: 1 0 100%;
  max-width: 490px;
  margin: 0 auto;
  transform: translateY(7.5rem);

  ${({ theme }) => theme.mq.mobile} {
    flex: 1 0 300px;
  }
`;

interface CoverProps {
  fluid: FluidObject | FluidObject[];
}

export const Cover = styled.img.attrs({
  imgStyle: {
    objectPosition: 'center left',
  },
})<CoverProps>`
  height: 100%;
  border-radius: 142px 15px;
`;
