import styled from 'styled-components';
import {
  SectionWrapper,
  SectionTitle,
  SectionSubtitle,
} from 'components/Common';

export const Wrapper = styled(SectionWrapper)`
  margin-bottom: 13rem;
`;

export const Title = styled(SectionTitle)`
  margin-bottom: 3.4rem;
  text-align: left;
`;

export const Subtitle = styled(SectionSubtitle)`
  max-width: 915px;
  margin-bottom: 3.5rem;
  text-align: center;
`;

export const OfferList = styled.div`
  display: grid;
  grid-column-gap: 3rem;
  grid-row-gap: 4.8rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  max-width: 915px;
`;
