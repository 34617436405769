import React, { FC } from 'react';
import Img, { FluidObject } from 'gatsby-image';

import LearnMore from 'components/Button/LearnMore';
import { Wrapper, Thumbnail, Title, Text, BtnWrapper } from './styles';

type Props = {
  title: string;
  text: string;
  fluid: FluidObject | FluidObject[];
  to: string;
  className?: string;
};

const OfferCard: FC<Props> = ({ title, text, fluid, to, className }) => (
  <Wrapper className={className}>
    <Thumbnail as={Img} fluid={fluid} alt="" />
    <Title>{title}</Title>
    <Text dangerouslySetInnerHTML={{ __html: text }} />
    <BtnWrapper>
      <LearnMore title="Dowiedz się więcej" to={to} />
    </BtnWrapper>
  </Wrapper>
);

export default OfferCard;
