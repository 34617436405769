import styled from 'styled-components';
import { FluidObject } from 'gatsby-image';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 430px;
  transition: transform 0.2s ease-in;
  outline: none;
  text-decoration: none;
`;

interface ThumbnailProps {
  fluid: FluidObject | FluidObject[];
}

export const Thumbnail = styled.div<ThumbnailProps>`
  height: 180px;
  margin-bottom: 1rem;
  border-radius: 6px;
`;

export const Title = styled.h3`
  margin: 0 0.6rem 0.8rem;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.4rem;
`;

export const Text = styled.p`
  margin: 0 0.6rem 0.8rem;
  font-size: 1.3rem;
  line-height: 2.4rem;
`;

export const BtnWrapper = styled.div`
  margin: auto 0.6rem 0;
`;
